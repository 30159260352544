import React, { useState } from "react";
import "./alert.scss";

interface AlertProps {
  message: string;
  type?: "success" | "error" | "warning" | "info";
  onClose?: () => void; // Optional close function
}

const Alert: React.FC<AlertProps> = ({ message, type = "info", onClose }) => {
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true); // Start the fade-out animation
    setTimeout(() => {
      if (onClose) {
        onClose();
      }
    }, 300); // Wait for animation to complete
  };

  return (
    <div className={`alert alert-${type} ${isClosing ? "fade-out" : ""}`}>
      <span>{message}</span>
      {onClose && (
        <button className="alert-close" onClick={handleClose}>
          &times;
        </button>
      )}
    </div>
  );
};

export default Alert;
