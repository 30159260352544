import React, { useState, useEffect } from "react";
import useAxios from "../../hook/useAxios.ts";
import "./viewProducts.scss";
import "./viewProductsResponsive.scss";
import EditProductModal from "./EditProductModal.tsx";
import ImageSlideshow from "./components/viewprod/ImageSlideshow.tsx";

interface Product {
  id: number;
  name: string;
  price: number;
  originalPrice: number;
  rating: number;
  ratingCount: number;
  ImageSrc: string[]; // Ensures ImageSrc is treated as an array of image URLs
  category: string;
  sizes: string[];
  color: string[];
  description: string;
}

const ViewProducts: React.FC = () => {
  const placeholderImage = "https://via.placeholder.com/150";
  const {
    data: products,
    loading,
    error,
    refetch,
    deleteData,
  } = useAxios<Product[]>("/products/getprod", [], "GET");

  console.log(products);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const productsPerPage = 6;

  const handleRowClick = (product: Product) => {
    setSelectedProduct(product);
  };

  const filteredProducts = products.filter(
    (product) =>
      product.name &&
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleEditClick = () => {
    setIsModalOpen(true);
  };

  const handleDeleteClick = async () => {
    if (selectedProduct) {
      const confirmDelete = window.confirm(
        `Are you sure you want to delete ${selectedProduct.name}?`
      );
      if (confirmDelete) {
        console.log("Deleting product with ID:", selectedProduct.id);
        await deleteData(`/products/delprod/${selectedProduct.id}`);
        refetch();
        setSelectedProduct(null);
      }
    }
  };

  const handleSave = () => {
    setIsModalOpen(false);
    refetch();
  };

  if (loading) return <p className="loading">Loading products...</p>;
  if (error) return <p className="error">Error loading products: {error}</p>;

  const parseJSON = (data: string | string[]): string[] => {
    if (Array.isArray(data)) return data;
    try {
      return JSON.parse(data.replace(/^"|"$/g, "").replace(/\\"/g, '"'));
    } catch (error) {
      console.error("Failed to parse JSON:", error);
      return [];
    }
  };

  const getImageSrc = (ImageSrc: string[] | null): string[] => {
    const imagePaths = ImageSrc ? parseJSON(ImageSrc) : [placeholderImage];
    return imagePaths.map(
      (path) => `${process.env.REACT_APP_API_BASE_URL}/${path}`
    );
  };

  return (
    <div className="view-products-container">
      <div className="product-table">
        <div className="search-bars">
          <input
            type="text"
            className="search-input"
            placeholder="Search products..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <table className="product-table__table">
          <thead className="product-table__thead">
            <tr className="product-table__row">
              <th className="product-table__header">Product Name</th>
              <th className="product-table__header">Price</th>
              <th className="product-table__header">Original Price</th>
              <th className="product-table__header">Rating</th>
              <th className="product-table__header">Rating Count</th>
              <th className="product-table__header">Category</th>
              <th className="product-table__header">Sizes</th>
              <th className="product-table__header">Color</th>
              <th className="product-table__header">Description</th>
            </tr>
          </thead>
          <tbody className="product-table__body">
            {currentProducts.map((product) => (
              <tr
                key={product.id}
                onClick={() => handleRowClick(product)}
                className={`product-table__row ${
                  selectedProduct?.id === product.id
                    ? "product-table__row--selected"
                    : ""
                }`}
              >
                <td className="product-table__data">{product.name}</td>
                <td className="product-table__data">₱{product.price}</td>
                <td className="product-table__data">
                  ₱{product.originalPrice}
                </td>
                <td className="product-table__data">{product.rating} / 5</td>
                <td className="product-table__data">{product.ratingCount}</td>
                <td className="product-table__data">{product.category}</td>
                <td className="product-table__data">
                  {product.sizes.join(", ")}
                </td>
                <td className="product-table__data">
                  {product.color.join(", ")}
                </td>
                <td className="product-table__data">{product.description}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pagination">
          <button
            className="pagination-button"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              className={`pagination-button ${
                currentPage === index + 1 ? "active" : ""
              }`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <button
            className="pagination-button"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>

      <div className="product-details">
        <div className="product-image">
          {selectedProduct && (
            <ImageSlideshow images={getImageSrc(selectedProduct.ImageSrc)} />
          )}
        </div>
        <div className="product-actions">
          <button
            className="product-actions__btn-edit"
            disabled={!selectedProduct}
            onClick={handleEditClick}
          >
            <i className="fas fa-edit"></i>Edit Product
          </button>
          <button
            className="product-actions__btn-delete"
            disabled={!selectedProduct}
            onClick={handleDeleteClick}
          >
            <i className="fas fa-trash"></i>Delete Product
          </button>
        </div>
      </div>

      {isModalOpen && selectedProduct && (
        <EditProductModal
          product={selectedProduct}
          onClose={() => setIsModalOpen(false)}
          onSave={() => {
            handleSave();
            refetch();
          }}
        />
      )}
    </div>
  );
};

export default ViewProducts;
