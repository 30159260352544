import React, { useState } from "react";
import "./ImageSlideshow.scss";

interface ImageSlideshowProps {
  images: string[];
}

const ImageSlideshow: React.FC<ImageSlideshowProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : images.length - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <div className="image-slideshow">
      <img
        src={images[currentIndex]}
        alt={`Slide ${currentIndex + 1}`}
        className="image-slideshow__img"
      />
      {images.length > 1 && (
        <>
          <button
            onClick={goToPrevious}
            className="image-slideshow__button image-slideshow__button--prev"
          >
            &lt;
          </button>
          <button
            onClick={goToNext}
            className="image-slideshow__button image-slideshow__button--next"
          >
            &gt;
          </button>
        </>
      )}
      <div className="image-slideshow__indicator">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageSlideshow;
