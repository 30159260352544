import React from "react";
import ProductView from "./components/ProductView.tsx";
import ProductCarousel from "./components/ProductCarousel.tsx";
import ProductTabs from "./components/ProductTabs.tsx";

interface ProductDetailsProps {
  product: {
    id: number;
    name: string;
    price: number | string;
    originalPrice?: number | string;
    rating: number | null;
    ratingCount: number;
    ImageSrc: string | string[];
    category: string;
    color: string | string[] | null;
    sizes: string | string[] | null;
  };
}

const ProductDetails: React.FC<ProductDetailsProps> = ({ product }) => {
  const colors = Array.isArray(product.color)
    ? product.color
    : product.color
    ? [product.color]
    : [];
  const sizes = Array.isArray(product.sizes)
    ? product.sizes
    : product.sizes
    ? [product.sizes]
    : [];

  const images = Array.isArray(product.ImageSrc)
    ? product.ImageSrc
    : [product.ImageSrc];

  return (
    <div className="container-fluid pb-5">
      <div className="row px-xl-5">
        <ProductCarousel ImageSrc={images} />
        <ProductView product={{ ...product, colors, sizes }} />
      </div>
      <ProductTabs product={product} />
    </div>
  );
};

export default ProductDetails;
