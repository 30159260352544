import React, { useEffect } from "react";
import img from "../../../../img/no_img.png";

interface ProductCarouselProps {
  ImageSrc: string[];
}

const ProductCarousel: React.FC<ProductCarouselProps> = ({ ImageSrc }) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:8000";

  useEffect(() => {
    const carouselElement = document.getElementById("product-carousel");
    if (carouselElement) {
      const bootstrap = require("bootstrap");
      new bootstrap.Carousel(carouselElement, {
        interval: 3000,
        wrap: true,
      });
    }
  }, []);

  return (
    <div className="col-lg-5 mb-30">
      <div
        id="product-carousel"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner bg-light">
          {ImageSrc && ImageSrc.length > 0 ? (
            ImageSrc.map((image, index) => (
              <div
                key={index}
                className={`carousel-item ${index === 0 ? "active" : ""}`}
              >
                <img
                  className="w-100 h-100"
                  style={{
                    maxHeight: "400px", // Set a maximum height
                    minHeight: "300px", // Set a minimum height
                    objectFit: "contain", // Ensures the image fits within the set height without distortion
                  }}
                  src={`${baseUrl}/${image.replace(/\\/g, "/")}`}
                  alt={`Product ${index + 1}`}
                />
              </div>
            ))
          ) : (
            <div className="carousel-item active">
              <img
                className="w-100 h-100"
                style={{
                  maxHeight: "400px",
                  minHeight: "300px",
                  objectFit: "contain",
                }}
                src={img}
                alt="No product available"
              />
            </div>
          )}
        </div>
        <a
          className="carousel-control-prev"
          href="#product-carousel"
          role="button"
          data-bs-slide="prev"
        >
          <span className="fa fa-2x fa-angle-left text-dark"></span>
        </a>
        <a
          className="carousel-control-next"
          href="#product-carousel"
          role="button"
          data-bs-slide="next"
        >
          <span className="fa fa-2x fa-angle-right text-dark"></span>
        </a>
        <ol className="carousel-indicators">
          {ImageSrc && ImageSrc.length > 0 ? (
            ImageSrc.map((_, index) => (
              <li
                key={index}
                data-bs-target="#product-carousel"
                data-bs-slide-to={index}
                className={index === 0 ? "active" : ""}
              ></li>
            ))
          ) : (
            <li
              data-bs-target="#product-carousel"
              data-bs-slide-to="0"
              className="active"
            ></li>
          )}
        </ol>
      </div>
    </div>
  );
};

export default ProductCarousel;
