import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Checkout.scss";
import CustomerInfo from "./components/Customerinfo.tsx";
import ProductInfo from "./components/ProductInfo.tsx";
import { useCart } from "../../context/CartContext.tsx";
import useAxios from "../../hook/useAxios.ts";

const Checkout: React.FC = () => {
  const { state, clearCart } = useCart();
  const [isFormCompleted, setFormCompleted] = useState(false);
  const [customerData, setCustomerData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  const { data, error, refetch } = useAxios("/orders/neworder", {}, "POST");

  const handleFormSubmit = (formData: any) => {
    setCustomerData(formData);
  };

  const handlePlaceOrder = () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    const productsWithFullImageSrc = state.items.map((product) => ({
      ...product,
      ImageSrc: product.ImageSrc.map((src) => `${baseUrl}/${src}`),
    }));

    const orderData = {
      customer: customerData,
      products: productsWithFullImageSrc,
    };

    setIsSubmitting(true);

    refetch("/orders/neworder", orderData, "POST")
      .then(() => {
        console.log("Order placed successfully", data);

        clearCart();
        setCustomerData({});
        setFormCompleted(false);

        navigate("/thank");
      })
      .catch((error) => {
        console.error("Error placing order:", error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="checkouts-container">
      <CustomerInfo
        onSubmit={handleFormSubmit}
        setFormCompleted={setFormCompleted}
      />

      <ProductInfo
        items={state.items}
        enablePlaceOrder={isFormCompleted}
        onPlaceOrder={handlePlaceOrder}
        loading={isSubmitting}
      />
      {error && <p>Error: {error}</p>}
    </div>
  );
};

export default Checkout;
