import React from "react";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../hook/useAxios.ts"; // Adjust path as necessary
import img from "../../../img/no_img.png";

interface Product {
  id: number;
  name: string;
  price: number | string;
  originalPrice?: number | string;
  rating: number;
  ratingCount: number;
  ImageSrc: string[];
  category: string;
  color: string | string[];
  sizes?: string[];
  Description: string;
}

const shuffleArray = (array: Product[]): Product[] => {
  return array
    .map((item) => ({ ...item, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ sort, ...item }) => item);
};

const MoreProduct: React.FC = () => {
  const navigate = useNavigate();
  const {
    data: products,
    loading,
    error,
  } = useAxios<Product[]>("/products/getprod", [], "GET");

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const randomProducts = shuffleArray(products).slice(0, 3);

  return (
    <div className="container-fluid py-5">
      <h2 className="section-title position-relative text-uppercase mx-xl-5 mb-4">
        <span className="bg-secondary pr-3">You May Also Like</span>
      </h2>
      <div className="row px-xl-5">
        {randomProducts.map((product) => (
          <div
            key={product.id}
            className="col-md-4 col-sm-6 mb-4"
            onClick={() => navigate(`/singleproduct/${product.id}`)} // Navigate to the product's page
          >
            <div className="product-item bg-light">
              <div className="product-image position-relative overflow-hidden">
                <img
                  className="img-fluid w-100"
                  src={`${process.env.REACT_APP_API_BASE_URL}/${
                    product.ImageSrc?.[0] || img
                  }`}
                  alt={product.name}
                  style={{
                    maxHeight: "250px",
                    minHeight: "250px",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div className="text-center py-4">
                <h6 className="text-decoration-none text-truncate">
                  {product.name}
                </h6>
                <div className="d-flex align-items-center justify-content-center mt-2">
                  <h5>${parseFloat(product.price as string).toFixed(2)}</h5>
                  {product.originalPrice && (
                    <h6 className="text-muted ml-2">
                      <del>
                        $
                        {parseFloat(product.originalPrice as string).toFixed(2)}
                      </del>
                    </h6>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-center mb-1">
                  {[...Array(5)].map((_, index) => (
                    <i
                      key={index}
                      className={`fa ${
                        index < product.rating ? "fa-star" : "far fa-star"
                      } text-primary mr-1`}
                    ></i>
                  ))}
                  <small>({product.ratingCount})</small>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MoreProduct;
