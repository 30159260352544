import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./App.scss";
import Header from "./components/Header.tsx";
import HomePage from "./pages/home/Home.tsx";
import ProductPage from "./pages/product/product.tsx";
import SingleProductPage from "./pages/SingleProd/SingleProd.tsx";
import CartBubbleButton from "./components/cart/CartBubbleButton.tsx";
import Footer from "./pages/home/components/footer.jsx";
import Checkout from "./pages/checkout/checkout.tsx";
import Blog from "./pages/blog/blog.tsx";
import About from "./pages/about/about.tsx";
import Admin from "./pages/admin/admin.tsx";
import Login from "./pages/admin/login.tsx";
import HeaderAdmin from "./pages/admin/components/headeradmin/headeradmin.tsx";
import ProductGrid from "./pages/admin/productGrid.tsx";
import PrivateRoute from "./pages/admin/privateRoute.tsx";
import ViewProducts from "./pages/admin/ViewProducts.tsx";
import ThankYou from "./pages/thankyou/thankyou.tsx";

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const location = useLocation();

  const isAdminRoute =
    location.pathname.startsWith("/admin") || location.pathname === "/login";

  const HideCartButtonOnRoutes = () => {
    const shouldHideCartButton = location.pathname === "/checkout";
    return <>{!shouldHideCartButton && <CartBubbleButton />}</>;
  };

  return (
    <div className="App">
      {!isAdminRoute && <Header />}
      {location.pathname.startsWith("/admin") && (
        <HeaderAdmin setIsAuthenticated={setIsAuthenticated} />
      )}

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/singleproduct/:id" element={<SingleProductPage />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/about" element={<About />} />
        <Route path="/thank" element={<ThankYou />} />

        <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/add-product" element={<ProductGrid />} />
          <Route path="/admin/view-products" element={<ViewProducts />} />
        </Route>

        <Route
          path="/login"
          element={<Login setIsAuthenticated={setIsAuthenticated} />}
        />
      </Routes>

      {!isAdminRoute && <HideCartButtonOnRoutes />}
      {!isAdminRoute && <Footer />}
    </div>
  );
};

export default App;
