import React from "react";

interface ProductFieldProps {
  label: string;
  value: string | number;
  type: "text" | "number";
  onChange: (value: string | number) => void;
}

const ProductField: React.FC<ProductFieldProps> = ({
  label,
  value,
  type,
  onChange,
}) => (
  <div className="product-form__field-card">
    <label>{label}:</label>
    <input
      type={type}
      value={value}
      onChange={(e) =>
        onChange(
          type === "number" ? parseFloat(e.target.value) : e.target.value
        )
      }
    />
  </div>
);

export default ProductField;
