import React from "react";
import Alert from "../../../../components/alert/Alert.tsx";

interface ProductImageUploadProps {
  ImageSrc: File[] | null;
  onChange: (files: File[] | null) => void;
  error: string | null;
}

const ProductImageUpload: React.FC<ProductImageUploadProps> = ({
  ImageSrc,
  onChange,
  error,
}) => {
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    const validImageTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/webp",
    ];
    const validFiles = files
      .filter((file) => validImageTypes.includes(file.type))
      .slice(0, 3);

    onChange(validFiles.length ? validFiles : null);
  };

  return (
    <div className="product-form__file-upload">
      <label htmlFor="file-upload">Click to upload up to 3 images</label>
      <input
        id="file-upload"
        type="file"
        accept="image/*"
        name="images"
        multiple
        onChange={handleImageChange}
      />
      <div className="product-form__image-preview-container">
        {ImageSrc &&
          ImageSrc.map((file, idx) => (
            <div key={idx} className="product-form__image-preview">
              <img src={URL.createObjectURL(file)} alt="Product Preview" />
            </div>
          ))}
      </div>
      {error && <Alert message={error} type="error" />}
    </div>
  );
};

export default ProductImageUpload;
