import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faCreditCard,
  faWallet,
  faMoneyBillWave,
} from "@fortawesome/free-solid-svg-icons";
import "./CustomerInfo.scss";

interface CustomerInfoProps {
  onSubmit: (formData: any) => void;
  setFormCompleted: (completed: boolean) => void;
}

const CustomerInfo: React.FC<CustomerInfoProps> = ({
  onSubmit,
  setFormCompleted,
}) => {
  const [formData, setFormData] = useState<any>({});
  const [enabledFields, setEnabledFields] = useState({
    email: true,
    fullName: false,
    contact: false,
    address: false,
    city: false,
    state: false,
    paymentMethod: false,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData: any) => ({ ...prevData, [name]: value }));
    onSubmit({ ...formData, [name]: value });

    if (name === "email" && value) {
      setEnabledFields((prev) => ({ ...prev, fullName: true }));
    }
    if (name === "fullName" && value) {
      setEnabledFields((prev) => ({ ...prev, contact: true }));
    }
    if (name === "contact" && value) {
      setEnabledFields((prev) => ({ ...prev, address: true }));
    }
    if (name === "address" && value) {
      setEnabledFields((prev) => ({ ...prev, city: true }));
    }
    if (name === "city" && value) {
      setEnabledFields((prev) => ({ ...prev, state: true }));
    }
    if (name === "state" && value) {
      setEnabledFields((prev) => ({ ...prev, paymentMethod: true }));
    }
  };

  const handlePaymentMethodSelect = (method: string) => {
    if (enabledFields.paymentMethod) {
      setFormData((prevData: any) => ({ ...prevData, paymentMethod: method }));
      onSubmit({ ...formData, paymentMethod: method });
    }
  };

  useEffect(() => {
    const allFieldsFilled = Object.values(formData).every((field) => field);
    setFormCompleted(allFieldsFilled && formData.paymentMethod);
  }, [formData, setFormCompleted]);

  return (
    <div className="customer-info-container">
      <div className="customer-info-section">
        <h3 className="customer-info-section-title">1. Enter Your Email</h3>
        <div className="customer-info-form-group">
          <input
            type="email"
            name="email"
            placeholder="Email Address*"
            className="customer-info-form-input"
            required
            onChange={handleInputChange}
            aria-label="Email"
            disabled={!enabledFields.email}
          />
          <button type="button" className="customer-info-form-submit">
            <FontAwesomeIcon icon={faEnvelope} />
          </button>
        </div>
      </div>

      <div className="customer-info-section customer-info-shipping-info">
        <h3 className="customer-info-section-title">2. Shipping</h3>
        <div className="customer-info-form-group customer-info-grid-layout">
          <input
            type="text"
            name="fullName"
            placeholder="Full Name*"
            className="customer-info-form-input"
            required
            onChange={handleInputChange}
            aria-label="Full Name"
            disabled={!enabledFields.fullName}
          />
          <input
            type="tel"
            name="contact"
            placeholder="Contact Number*"
            className="customer-info-form-input"
            required
            onChange={handleInputChange}
            aria-label="Contact Number"
            disabled={!enabledFields.contact}
          />
          <input
            type="text"
            name="city"
            placeholder="City*"
            className="customer-info-form-input"
            required
            onChange={handleInputChange}
            aria-label="City"
            disabled={!enabledFields.city}
          />
          <input
            type="text"
            name="state"
            placeholder="State/Province/Region*"
            className="customer-info-form-input"
            required
            onChange={handleInputChange}
            aria-label="State"
            disabled={!enabledFields.state}
          />
          <input
            type="text"
            name="address"
            placeholder="Address*"
            className="customer-info-form-input customer-info-full-width"
            required
            onChange={handleInputChange}
            aria-label="Address"
            disabled={!enabledFields.address}
          />
        </div>
      </div>

      <div className="customer-info-section">
        <h3 className="customer-info-section-title">3. Payment Method</h3>
        <div className="customer-info-form-group customer-info-payment-method">
          <button
            type="button"
            className="customer-info-payment-button"
            onClick={() => handlePaymentMethodSelect("Gcash")}
            disabled={!enabledFields.paymentMethod}
          >
            <FontAwesomeIcon icon={faWallet} />
            Gcash
          </button>
          <button
            type="button"
            className="customer-info-payment-button"
            onClick={() => handlePaymentMethodSelect("COD")}
            disabled={true} // Disabled for now
          >
            <FontAwesomeIcon icon={faMoneyBillWave} />
            Cash on Delivery (COD)
          </button>
          <button
            type="button"
            className="customer-info-payment-button"
            onClick={() => handlePaymentMethodSelect("BankCredit")}
            disabled={true} // Disabled for now
          >
            <FontAwesomeIcon icon={faCreditCard} />
            Bank/Credit
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerInfo;
