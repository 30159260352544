import React from "react";
import FilterSection from "./FilterSection.tsx";

interface Counts {
  [key: string]: number;
}

interface Filters {
  price: string[];
  color: string[];
  category: string[];
  sizes: string[];
}

interface FilterComponentProps {
  onFilterChange: (idPrefix: string, filterLabel: string) => void;
  priceCounts: Counts;
  colorCounts: Counts;
  categoryCounts: Counts;
  sizeCounts: Counts;
  selectedFilters: Filters;
  products: Product[];
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  onFilterChange,
  priceCounts,
  colorCounts,
  categoryCounts,
  sizeCounts,
  selectedFilters,
  products,
}) => {
  const maxPrice = Math.max(...products.map((product) => product.price));
  const priceRangeStep = 100;
  const priceRanges = [];

  for (let price = 0; price <= maxPrice; price += priceRangeStep) {
    const rangeStart = price;
    const rangeEnd = price + priceRangeStep;
    if (rangeEnd > maxPrice && rangeStart < maxPrice) {
      priceRanges.push(`$${rangeStart} - $${maxPrice}`);
      break;
    } else if (rangeEnd > maxPrice) {
      break;
    } else {
      priceRanges.push(`$${rangeStart} - $${rangeEnd}`);
    }
  }

  const isPriceInRange = (price: number, range: string): boolean => {
    const [min, max] = range
      .split(" - ")
      .map((value) => parseFloat(value.replace("$", "")));
    return price >= min && price <= max;
  };

  const priceFilters = priceRanges.map((range) => {
    const count = products.filter((product) =>
      isPriceInRange(product.price, range)
    ).length;
    return {
      label: range,
      count,
      defaultChecked: selectedFilters.price?.includes(range) || false,
    };
  });

  const colorFilters = Array.from(
    new Set(
      products.flatMap((product) =>
        typeof product.color === "string" ? [product.color] : product.color
      )
    )
  )
    .sort()
    .map((color) => ({
      label: color,
      count: colorCounts[color] || 0,
      defaultChecked: selectedFilters.color?.includes(color) || false,
    }));

  const categoryFilters = Array.from(
    new Set(products.map((product) => product.category))
  )
    .sort()
    .map((category) => ({
      label: category,
      count: categoryCounts[category] || 0,
      defaultChecked: selectedFilters.category?.includes(category) || false,
    }));

  const sizeFilters = Array.from(
    new Set(products.flatMap((product) => product.sizes))
  )
    .sort()
    .map((size) => ({
      label: size,
      count: sizeCounts[size] || 0,
      defaultChecked: selectedFilters.sizes?.includes(size) || false,
    }));

  return (
    <div className="col-lg-3 col-md-4">
      <FilterSection
        title="Filter by price"
        filters={priceFilters}
        idPrefix="price"
        onFilterChange={onFilterChange}
      />
      <FilterSection
        title="Filter by color"
        filters={colorFilters}
        idPrefix="color"
        onFilterChange={onFilterChange}
      />
      <FilterSection
        title="Filter by category"
        filters={categoryFilters}
        idPrefix="category"
        onFilterChange={onFilterChange}
      />
      <FilterSection
        title="Filter by size"
        filters={sizeFilters}
        idPrefix="sizes"
        onFilterChange={onFilterChange}
      />
    </div>
  );
};

export default FilterComponent;
