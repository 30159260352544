// NavCollapse.tsx
import React from "react";
import { Link } from "react-router-dom";
import "./NavCollapse.scss";

interface NavCollapseProps {
  isNavOpen: boolean;
}

const NavCollapse: React.FC<NavCollapseProps> = ({ isNavOpen }) => {
  return (
    <nav className={`navcollapse-navbar ${isNavOpen ? "open" : ""}`}>
      <ul className="navcollapse-links">
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/product">Products</Link>
        </li>
        <li>
          <Link to="/blog">Blog</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavCollapse;
