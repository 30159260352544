import React from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

interface ProductSelectFieldProps {
  label: string;
  value: string[];
  options: string[];
  onChange: (value: string[]) => void;
}

const ProductSelectField: React.FC<ProductSelectFieldProps> = ({
  label,
  value,
  options,
  onChange,
}) => {
  const [selectOptions, setSelectOptions] = React.useState(
    options.map((option) => ({ label: option, value: option }))
  );

  const handleChange = (selectedOptions: any) => {
    const values = selectedOptions
      ? selectedOptions.map((opt: any) => opt.value)
      : [];
    onChange(values);
  };

  const handleCreateOption = (inputValue: string) => {
    const newOption = { label: inputValue, value: inputValue };
    setSelectOptions((prev) => [...prev, newOption]);
    onChange([...value, inputValue]);
  };

  return (
    <div className="product-form__field-card">
      <label>{label}:</label>
      <CreatableSelect
        isMulti
        options={selectOptions}
        value={selectOptions.filter((opt) => value.includes(opt.value))}
        onChange={handleChange}
        onCreateOption={handleCreateOption}
        placeholder="Select or type to add..."
        isClearable
        classNamePrefix="custom-select"
      />
    </div>
  );
};

export default ProductSelectField;
