import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import useAxios from "../../hook/useAxios.ts";
import "./editProductModal.scss";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

interface Product {
  id: number;
  name: string;
  price: number;
  originalPrice: number;
  rating: number;
  ratingCount: number;
  ImageSrc: string[] | null;
  category: string;
  sizes: string[];
  color: string[];
  description: string;
}

interface EditProductModalProps {
  product: Product | null;
  onClose: () => void;
  onSave: (updatedProduct: Product) => void;
}

const EditProductModal: React.FC<EditProductModalProps> = ({
  product,
  onClose,
  onSave,
}) => {
  const [formData, setFormData] = useState<Product | null>(product);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);

  const { loading, error, refetch } = useAxios<Product>(
    `/products/editprod/${product?.id}`,
    null,
    "POST"
  );

  useEffect(() => {
    if (product) {
      setFormData(product);
      const initialImagePreviews = Array.isArray(product.ImageSrc)
        ? product.ImageSrc.map((src) => `${apiBaseUrl}/${src}`)
        : [];
      setImagePreviews(initialImagePreviews);
    }
  }, [product]);

  if (!product) return null;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => prev && { ...prev, [name]: value });
  };

  const handleSelectChange = (selectedOptions, fieldName) => {
    const values = selectedOptions
      ? selectedOptions.map((opt) => opt.value)
      : [];
    setFormData((prev) => prev && { ...prev, [fieldName]: values });
  };

  const handleCreateOption = (inputValue: string, fieldName: string) => {
    setFormData(
      (prev) =>
        prev && {
          ...prev,
          [fieldName]: [...(prev[fieldName] || []), inputValue],
        }
    );
  };

  const colorOptions = Array.from(
    new Set([
      ...(formData?.color.map((color) => ({ value: color, label: color })) ||
        []),
      { value: "Blue", label: "Blue" },
      { value: "Green", label: "Green" },
      { value: "Red", label: "Red" },
    ])
  )
    .map((option) => JSON.stringify(option))
    .map((option) => JSON.parse(option));

  const sizeOptions = Array.from(
    new Set([
      ...(formData?.sizes.map((size) => ({ value: size, label: size })) || []),
      { value: "XS", label: "XS" },
      { value: "S", label: "S" },
      { value: "M", label: "M" },
      { value: "L", label: "L" },
      { value: "XL", label: "XL" },
    ])
  )
    .map((option) => JSON.stringify(option))
    .map((option) => JSON.parse(option));

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    const totalImages = files.length + imagePreviews.length;

    if (totalImages <= 3) {
      setImageFiles((prev) => [...prev, ...files]);
      const newPreviews = files.map((file) => URL.createObjectURL(file));
      setImagePreviews((prev) => [...prev, ...newPreviews]);
    } else {
      alert("You can upload a maximum of 3 images.");
    }
  };

  const handleRemoveImage = (index: number) => {
    setImagePreviews((prev) => prev.filter((_, i) => i !== index));
    setImageFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    if (formData) {
      const formPayload = new FormData();
      formPayload.append("name", formData.name);
      formPayload.append("price", formData.price.toString());
      formPayload.append("originalPrice", formData.originalPrice.toString());
      formPayload.append("rating", formData.rating.toString());
      formPayload.append("ratingCount", formData.ratingCount.toString());
      formPayload.append("category", formData.category);
      formPayload.append("sizes", JSON.stringify(formData.sizes));
      formPayload.append("color", JSON.stringify(formData.color));
      formPayload.append("description", formData.description);

      const baseUrl = `${apiBaseUrl}/`;
      imagePreviews
        .filter((src) => !src.startsWith("blob:"))
        .forEach((src) => {
          const relativePath = src.replace(baseUrl, "");
          formPayload.append("existingImages[]", relativePath);
        });

      imageFiles.forEach((file) => {
        formPayload.append("images", file);
      });

      try {
        await refetch(`/products/editprod/${product.id}`, formPayload, "POST");
        if (!error) {
          onSave(formData);
          onClose();
        }
      } catch (err) {
        console.error("Error saving product:", err);
      }
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>Edit Product</h2>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="modal-body">
          <label>
            Product Name:
            <input
              type="text"
              name="name"
              value={formData?.name || ""}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Price:
            <input
              type="number"
              name="price"
              value={formData?.price || ""}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Original Price:
            <input
              type="number"
              name="originalPrice"
              value={formData?.originalPrice || ""}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Rating:
            <input
              type="number"
              name="rating"
              value={formData?.rating || ""}
              onChange={handleInputChange}
              max="5"
            />
          </label>
          <label>
            Category:
            <input
              type="text"
              name="category"
              value={formData?.category || ""}
              onChange={handleInputChange}
            />
          </label>

          <label>Sizes:</label>
          <CreatableSelect
            isMulti
            options={sizeOptions}
            value={sizeOptions.filter((option) =>
              formData?.sizes.includes(option.value)
            )}
            onChange={(selectedOptions) =>
              handleSelectChange(selectedOptions, "sizes")
            }
            onCreateOption={(inputValue) =>
              handleCreateOption(inputValue, "sizes")
            }
          />

          <label>Color:</label>
          <CreatableSelect
            isMulti
            options={colorOptions}
            value={colorOptions.filter((option) =>
              formData?.color.includes(option.value)
            )}
            onChange={(selectedOptions) =>
              handleSelectChange(selectedOptions, "color")
            }
            onCreateOption={(inputValue) =>
              handleCreateOption(inputValue, "color")
            }
          />

          <label>
            Description:
            <textarea
              name="description"
              value={formData?.description || ""}
              onChange={handleInputChange}
            />
          </label>

          <label>Upload Images (max 3):</label>
          <input
            type="file"
            multiple
            onChange={handleImageChange}
            accept="image/*"
          />
          <div className="image-preview-container">
            {imagePreviews.map((src, index) => (
              <div key={index} className="image-preview">
                <img src={src} alt={`Preview ${index + 1}`} />
                <button onClick={() => handleRemoveImage(index)}>Remove</button>
              </div>
            ))}
          </div>
        </div>
        <div className="modal-footer">
          <button className="save-button" onClick={handleSave}>
            Save
          </button>

          {error && <p className="error-message">Error: {error}</p>}
        </div>
      </div>
    </div>
  );
};

export default EditProductModal;
