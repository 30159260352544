import React from "react";
import { useCart } from "../../../context/CartContext.tsx";
import "./ProductInfo.scss";

interface ProductInfoProps {
  items: Array<{
    id: string;
    name: string;
    ImageSrc: string[];
    size: string;
    color: string;
    quantity: number;
    price: number;
  }>;
  enablePlaceOrder: boolean;
  onPlaceOrder: () => void;
  loading: boolean;
}

const ProductInfo: React.FC<ProductInfoProps> = ({
  items,
  enablePlaceOrder,
  onPlaceOrder,
  loading,
}) => {
  const { removeItem } = useCart();

  const subtotal = items.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  return (
    <div className="product-info">
      <h2 className="product-info-title">Cart ({items.length})</h2>
      <div className="scroll-container">
        {items.map((item, index) => (
          <div key={index} className="product-card">
            <img
              src={
                item.ImageSrc && item.ImageSrc.length > 0
                  ? `${baseUrl}/${item.ImageSrc[0]}`
                  : "path/to/default/image.png"
              }
              alt={item.name}
              className="checkout-image"
            />
            <div className="product-details">
              <h3 className="product-name">{item.name}</h3>
              <p className="product-description">
                {item.size} | {item.color}
              </p>
              <p className="product-quantity">
                <strong>Quantity:</strong> {item.quantity}
              </p>
              <button
                className="remove-product-button"
                onClick={() => removeItem(item.id)}
              >
                Remove Product
              </button>
            </div>
            <p className="product-price">₱{item.price.toFixed(2)}</p>
          </div>
        ))}
      </div>

      <div className="checkout-summary">
        <input
          type="text"
          className="promo-code-input"
          placeholder="Gift or promo code"
          disabled
        />
        <button className="apply-button" disabled>
          Apply
        </button>

        <div className="summary-line">
          <span>Subtotal</span>
          <span>₱{subtotal.toFixed(2)}</span>
        </div>
        <div className="summary-line summary-line-total">
          <span>Total</span>
          <span>₱{subtotal.toFixed(2)}</span>
        </div>
      </div>

      <button
        className={`place-order-button ${loading ? "loading" : ""}`}
        disabled={!enablePlaceOrder || loading}
        onClick={onPlaceOrder}
      >
        {loading ? <div className="checkout-spinner"></div> : "Place Order"}
      </button>
    </div>
  );
};

export default ProductInfo;
