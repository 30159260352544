import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./HeaderAdmin.scss";

interface HeaderAdminProps {
  setIsAuthenticated: (isAuthenticated: boolean) => void;
}

const HeaderAdmin: React.FC<HeaderAdminProps> = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear the authentication state and navigate to the login page
    setIsAuthenticated(false);
    navigate("/login");
  };

  return (
    <header className="header-admin">
      <div className="header-admin__container">
        <h1 className="header-admin__title">Admin Panel</h1>
        <nav className="header-admin__nav">
          <NavLink
            to="/admin/add-product"
            className="header-admin__link"
            activeClassName="active"
          >
            Add Product
          </NavLink>
          <NavLink
            to="/admin/view-products"
            className="header-admin__link"
            activeClassName="active"
          >
            View Products
          </NavLink>
          <button className="header-admin__logout" onClick={handleLogout}>
            Logout
          </button>
        </nav>
      </div>
    </header>
  );
};

export default HeaderAdmin;
