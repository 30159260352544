import React from "react";
import Alert from "../../../../components/alert/Alert.tsx";

interface AlertsProps {
  errors: string[];
  successMessage: string | null;
  errorMessage: string | null;
  clearSuccess: () => void;
  clearError: () => void;
}

const Alerts: React.FC<AlertsProps> = ({
  errors,
  successMessage,
  errorMessage,
  clearSuccess,
  clearError,
}) => (
  <>
    {errors.map((error, index) => (
      <Alert key={index} message={error} type="error" />
    ))}
    {successMessage && (
      <Alert message={successMessage} type="success" onClose={clearSuccess} />
    )}
    {errorMessage && (
      <Alert message={errorMessage} type="error" onClose={clearError} />
    )}
  </>
);

export default Alerts;
