import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAxios from "../../../hook/useAxios.ts";
import "./Product.css"; // Import the CSS file for styling

function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

interface Product {
  id: number;
  name: string;
  price: string | number;
  originalPrice: string | number;
  rating: number | null;
  ratingCount: number;
  ImageSrc: string[];
  category: string;
}

const Product: React.FC = () => {
  const navigate = useNavigate();

  const {
    data: products,
    loading,
    error,
  } = useAxios<Product[]>("/products/getprod", [], "GET");

  const randomProducts =
    products.length > 0 ? shuffleArray([...products]).slice(0, 6) : [];

  const handleCategoryClick = (category: string) => {
    navigate("/product", { state: { category } });
  };

  const handleBuyNowClick = (productId: number) => {
    navigate(`/singleproduct/${productId}`);
  };

  const getFullImageUrl = (imagePath: string) => {
    const baseUrl =
      process.env.REACT_APP_API_BASE_URL || "http://localhost:8000";
    const finalUrl = `${baseUrl}/${imagePath}`
      .replace(/\\/g, "/")
      .replace(/([^:]\/)\/+/g, "$1");
    return finalUrl;
  };

  if (loading) {
    return <div>Loading products...</div>;
  }

  if (error) {
    return <div>Error loading products: {error}</div>;
  }

  return (
    <section className="product_section layout_padding" id="prodslide">
      <div className="container">
        <div className="heading_container heading_center">
          <h2>
            Our <span>products</span>
          </h2>
        </div>
        <div className="row">
          {randomProducts.map((product) => {
            const price = Number(product.price);
            const originalPrice = Number(product.originalPrice);

            const selectedImage =
              product.ImageSrc && product.ImageSrc.length > 0
                ? product.ImageSrc[
                    Math.floor(Math.random() * product.ImageSrc.length)
                  ]
                : "img/no_img.png";

            const imageUrl = getFullImageUrl(selectedImage);

            return (
              <div key={product.id} className="col-sm-6 col-md-4 col-lg-4">
                <div className="box">
                  <div className="option_container">
                    <div className="options">
                      <a
                        href="#"
                        className="option1"
                        onClick={() => handleCategoryClick(product.category)}
                      >
                        {product.category}
                      </a>
                      <a
                        href="#"
                        className="option2"
                        onClick={() => handleBuyNowClick(product.id)}
                      >
                        Buy Now
                      </a>
                    </div>
                  </div>
                  <div className="Image-box">
                    <img
                      className="product-imagess"
                      src={imageUrl}
                      alt={product.name}
                    />
                  </div>
                  <div className="detail-box">
                    <h5>{product.name}</h5>
                    <h6>${price.toFixed(2)}</h6>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="btn-box">
          <Link to="/product">View All products</Link>
        </div>
      </div>
    </section>
  );
};

export default Product;
