import React from "react";

interface ProductTextareaProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
}

const ProductTextarea: React.FC<ProductTextareaProps> = ({
  label,
  value,
  onChange,
}) => (
  <div className="product-form__card">
    <label>{label}:</label>
    <textarea value={value} onChange={(e) => onChange(e.target.value)} />
  </div>
);

export default ProductTextarea;
