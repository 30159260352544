import { useState, useEffect } from "react";
import axios, { AxiosResponse } from "axios";

type Method = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

interface AxiosOptions {
  url?: string;
  bodyData?: Record<string, any> | FormData | null;
  method?: Method;
}

const useAxios = <T>(endpoint: string, initialData: T, defaultMethod: Method = "GET") => {
  const [data, setData] = useState<T>(initialData);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const url = `${process.env.REACT_APP_API_BASE_URL}${endpoint}`;

  const fetchData = async ({ url: customUrl = url, bodyData = null, method = defaultMethod }: AxiosOptions): Promise<void> => {
    setLoading(true);
    try {
      let response: AxiosResponse<T>;

      switch (method) {
        case "GET":
          response = await axios.get<T>(customUrl);
          break;
        case "POST":
          response = await axios.post<T>(customUrl, bodyData);
          break;
        case "PUT":
          response = await axios.put<T>(customUrl, bodyData);
          break;
        case "PATCH":
          response = await axios.patch<T>(customUrl, bodyData);
          break;
        case "DELETE":
          response = await axios.delete<T>(customUrl);
          break;
        default:
          throw new Error("Unsupported HTTP method");
      }

      setData(response.data);
      setError(null);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (defaultMethod === "GET") {
      fetchData({ method: "GET" });
    }
  }, [url]);

  const refetch = (newEndpoint?: string, newBodyData?: Record<string, any> | FormData | null, newMethod: Method = "GET"): Promise<void> => {
    const customUrl = newEndpoint ? `${process.env.REACT_APP_API_BASE_URL}${newEndpoint}` : url;
    setData(initialData);
    return fetchData({ url: customUrl, bodyData: newBodyData, method: newMethod });
  };

  const deleteData = async (deleteEndpoint?: string): Promise<void> => {
    const customUrl = deleteEndpoint ? `${process.env.REACT_APP_API_BASE_URL}${deleteEndpoint}` : url;
    await fetchData({ url: customUrl, method: "DELETE" });
  };

  return { data, loading, error, refetch, deleteData };
};

export default useAxios;
