import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiTruck, FiSearch } from "react-icons/fi";
import logo from "../img/logobazaar.png";
import "./Header.scss";
import NavCollapse from "./NavCollapse.tsx";

const Header: React.FC = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <header className="header">
      <div className="header-container">
        <div className="logo-collapse-container">
          <Link to="/" className="logo">
            <img src={logo} alt="Logo" />
          </Link>

          {/* Collapse Button for Mobile */}
          <button
            className={`collapse-button ${isNavOpen ? "collapsed" : ""}`}
            onClick={toggleNav}
          >
            <span className="icon">{isNavOpen ? "✖" : "☰"}</span>
          </button>
        </div>

        <NavCollapse isNavOpen={isNavOpen} />

        <div className="iconsz">
          <Link to="/order-tracking" className="iconss">
            <FiTruck size={20} />
            <span>Order Tracking</span>
          </Link>
          <Link to="/search" className="iconss">
            <FiSearch size={20} />
            <span>Search</span>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
