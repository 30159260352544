import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.scss";

interface LoginProps {
  setIsAuthenticated: (isAuthenticated: boolean) => void;
}

const Login: React.FC<LoginProps> = ({ setIsAuthenticated }) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleLogin = () => {
    const adminUser = "admin";
    const adminPass = "admin";

    if (username === adminUser && password === adminPass) {
      setIsLoading(true);
      setTimeout(() => {
        setIsAuthenticated(true);
        navigate("/admin");
      }, 2000);
    } else {
      alert("Incorrect username or password");
    }
  };

  return (
    <div className="login-container">
      {!isLoading ? (
        <div className="login-box">
          <h2>Admin Login</h2>
          <div className="input-group">
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="login-input"
              disabled={isLoading}
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="login-input"
              disabled={isLoading}
            />
          </div>
          <button
            className="login-button"
            onClick={handleLogin}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Login"}
          </button>
        </div>
      ) : (
        <div className="loading-screen">
          <div className="loader"></div>
          <p>Loading to dashboard...</p>
        </div>
      )}
    </div>
  );
};

export default Login;
