import React, { useState } from "react";
import ProductField from "./components/productadd/ProductField.tsx";
import ProductSelectField from "./components/productadd/ProductSelectField.tsx";
import ProductImageUpload from "./components/productadd/ProductImageUpload.tsx";
import ProductTextarea from "./components/productadd/ProductTextarea.tsx";
import Alerts from "./components/productadd/Alerts.tsx";
import useAxios from "../../hook/useAxios.ts";
import "./productGrid.css";

const initialProductState = {
  id: 1,
  productName: "",
  price: 0,
  originalPrice: 0,
  rating: 0,
  ratingCount: 0,
  ImageSrc: null,
  category: "",
  sizes: [], // Updated to hold an array for multiple selections
  color: [], // Updated to hold an array for multiple selections
  description: "",
};

const ProductGrid: React.FC = () => {
  const [product, setProduct] = useState(initialProductState);
  const { refetch } = useAxios("/products/postprod", {}, "POST");
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [imageError, setImageError] = useState<string | null>(null);

  const handleInputChange = (field: string, value: any) => {
    setProduct((prev) => ({ ...prev, [field]: value }));
  };

  const validateForm = () => {
    const errors = [];
    if (!product.productName) errors.push("Product Name is required.");
    if (product.price <= 0) errors.push("Price must be greater than 0.");
    if (product.originalPrice <= 0)
      errors.push("Original Price must be greater than 0.");
    if (!product.category) errors.push("Category is required.");
    if (product.sizes.length === 0) errors.push("Size is required.");
    if (product.color.length === 0) errors.push("Color is required.");
    if (!product.description) errors.push("Description is required.");

    setValidationErrors(errors);
    return errors.length === 0;
  };

  const addProduct = async () => {
    if (validateForm()) {
      const formData = new FormData();
      Object.keys(product).forEach((key) => {
        if (key === "ImageSrc" && product[key]) {
          product[key].forEach((file) => {
            formData.append("images", file); // Append each file separately
          });
        } else if (Array.isArray(product[key])) {
          formData.append(key, JSON.stringify(product[key])); // Convert arrays to JSON strings
        } else {
          formData.append(key, product[key]);
        }
      });
      try {
        await refetch("/products/postprod", formData, "POST");
        setSuccessMessage("Product added successfully!");
        setValidationErrors([]);
        setProduct(initialProductState);
      } catch (err) {
        setErrorMessage("Failed to add product.");
      }
    }
  };

  return (
    <div className="product-form">
      <h2 className="product-form__title">Product Form</h2>
      <div className="product-form__grid">
        <div className="product-form__fields">
          <ProductField
            label="Product Name"
            value={product.productName}
            type="text"
            onChange={(val) => handleInputChange("productName", val)}
          />
          <ProductField
            label="Price"
            value={product.price}
            type="number"
            onChange={(val) => handleInputChange("price", val)}
          />
          <ProductField
            label="Original Price"
            value={product.originalPrice}
            type="number"
            onChange={(val) => handleInputChange("originalPrice", val)}
          />
          <ProductField
            label="Category"
            value={product.category}
            type="text"
            onChange={(val) => handleInputChange("category", val)}
          />
          <ProductSelectField
            label="Sizes"
            value={product.sizes}
            options={["XS", "S", "M", "L", "XL"]}
            onChange={(val) => handleInputChange("sizes", val)}
          />
          <ProductSelectField
            label="Color"
            value={product.color}
            options={["Red", "Blue", "Green", "Black", "White"]}
            onChange={(val) => handleInputChange("color", val)}
          />
        </div>

        <div className="product-form__card-container">
          <ProductTextarea
            label="Description"
            value={product.description}
            onChange={(val) => handleInputChange("description", val)}
          />
          <ProductImageUpload
            ImageSrc={product.ImageSrc}
            onChange={(file) => handleInputChange("ImageSrc", file)}
            error={imageError}
          />
        </div>
      </div>

      <Alerts
        errors={validationErrors}
        successMessage={successMessage}
        errorMessage={errorMessage}
        clearSuccess={() => setSuccessMessage(null)}
        clearError={() => setErrorMessage(null)}
      />

      <button className="product-form__add-button" onClick={addProduct}>
        Add Product
      </button>
    </div>
  );
};

export default ProductGrid;
