import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import "./product.scss";
import ProductDetails from "./ProductDetails/ProductDetails.tsx";
import MoreProduct from "./MoreProduct/MoreProduct.tsx";
import useAxios from "../../hook/useAxios.ts";

interface Product {
  id: number;
  name: string;
  price: number | string;
  originalPrice?: number | string;
  rating: number | null;
  ratingCount: number;
  ImageSrc: string;
  category: string;
  color: string;
  size?: string;
}

const SingleProductPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const {
    data: product,
    loading,
    error,
    refetch,
  } = useAxios<Product | null>(`/products/getsing/${id}`, null, "GET");

  // Trigger refetch only when `id` changes
  useEffect(() => {
    refetch(`/products/getsing/${id}`);
  }, [id]);

  if (loading) {
    return <div>Loading product details...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div>
      <ProductDetails product={product} />
      <MoreProduct />
    </div>
  );
};

export default SingleProductPage;
