import React, { useState, useEffect } from "react";
import useAxios from "../../hook/useAxios.ts";
import "./product.scss";
import FilterComponent from "./component/FilterComponent.tsx";
import SortingAndShowingComponent from "./component/SortingAndShowingComponent.tsx";
import ProductItem from "./component/ProductItem.tsx";
import Pagination from "./component/Pagination.tsx";
import { useLocation } from "react-router-dom";
import "./loading.scss";

interface Product {
  id: number;
  name: string;
  price: number;
  originalPrice: number;
  color: string[];
  category: string;
  ImageSrc: string[];
  rating: number;
  ratingCount: number;
  sizes: string[];
}

interface Filters {
  price: string[];
  color: string[];
  category: string[];
  sizes: string[];
}

interface Counts {
  [key: string]: number;
}

const ProductPage: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedFilters, setSelectedFilters] = useState<Filters>({
    price: [],
    color: [],
    category: [],
    sizes: [],
  });

  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);

  const [priceCounts, setPriceCounts] = useState<Counts>({});
  const [colorCounts, setColorCounts] = useState<Counts>({});
  const [categoryCounts, setCategoryCounts] = useState<Counts>({});
  const [sizeCounts, setSizeCounts] = useState<Counts>({});

  const { data: products, loading } = useAxios<Product[]>(
    "/products/getprod",
    [],
    "GET"
  );
  const location = useLocation();

  useEffect(() => {
    const state = location.state as { category?: string } | null;
    if (state?.category) {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        category: [state.category],
      }));
    }
  }, [location.state]);

  const parsePriceRange = (range: string): [number, number] => {
    const [min, max] = range
      .split(" - ")
      .map((value) => parseFloat(value.replace("$", "")));
    return [min, max];
  };

  const applyFilters = (): Product[] => {
    let result = [...products];

    if (selectedFilters.category.length) {
      result = result.filter((product) =>
        selectedFilters.category.includes(product.category)
      );
    }
    if (selectedFilters.price.length) {
      result = result.filter((product) =>
        selectedFilters.price.some((priceRange) => {
          const [min, max] = parsePriceRange(priceRange);
          return product.price >= min && product.price <= max;
        })
      );
    }
    if (selectedFilters.color.length) {
      result = result.filter((product) =>
        product.color.some((col) => selectedFilters.color.includes(col))
      );
    }
    if (selectedFilters.sizes.length) {
      result = result.filter((product) =>
        product.sizes.some((size) => selectedFilters.sizes.includes(size))
      );
    }

    return result;
  };

  const recalculateCounts = (filteredProducts: Product[]) => {
    const newPriceCounts: Counts = {};
    const newColorCounts: Counts = {};
    const newCategoryCounts: Counts = {};
    const newSizeCounts: Counts = {};

    filteredProducts.forEach((product) => {
      const priceRange = `$${Math.floor(product.price / 100) * 100} - $${
        Math.ceil(product.price / 100) * 100
      }`;
      newPriceCounts[priceRange] = (newPriceCounts[priceRange] || 0) + 1;

      product.color.forEach((col) => {
        newColorCounts[col] = (newColorCounts[col] || 0) + 1;
      });

      newCategoryCounts[product.category] =
        (newCategoryCounts[product.category] || 0) + 1;

      product.sizes.forEach((size) => {
        newSizeCounts[size] = (newSizeCounts[size] || 0) + 1;
      });
    });

    setPriceCounts(newPriceCounts);
    setColorCounts(newColorCounts);
    setCategoryCounts(newCategoryCounts);
    setSizeCounts(newSizeCounts);
  };

  useEffect(() => {
    const filtered = applyFilters();
    setFilteredProducts(filtered);
    setTotalPages(Math.ceil(filtered.length / 9));
    recalculateCounts(filtered);
  }, [selectedFilters, products]);

  const handleFilterChange = (filterType: keyof Filters, value: string) => {
    setSelectedFilters((prev) => {
      const newFilters = prev[filterType].includes(value)
        ? prev[filterType].filter((item) => item !== value)
        : [...prev[filterType], value];
      return { ...prev, [filterType]: newFilters };
    });
  };

  return (
    <div className="container-fluid">
      <div className="row px-xl-5">
        <FilterComponent
          onFilterChange={handleFilterChange}
          priceCounts={priceCounts}
          colorCounts={colorCounts}
          categoryCounts={categoryCounts}
          sizeCounts={sizeCounts}
          selectedFilters={selectedFilters}
          products={products}
        />
        <div className="col-lg-9 col-md-8">
          <div className="row pb-3">
            <SortingAndShowingComponent
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
            {loading ? (
              <div className="loading-circle"></div>
            ) : (
              filteredProducts.map((product) => (
                <ProductItem
                  key={product.id}
                  id={product.id}
                  ImageSrc={product.ImageSrc}
                  productName={product.name}
                  price={`$${product.price}`}
                  originalPrice={`$${product.originalPrice}`}
                  rating={product.rating}
                  ratingCount={product.ratingCount}
                />
              ))
            )}
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
