import React from "react";
import "./ThankYou.scss";

const ThankYou: React.FC = () => {
  return (
    <div className="thankyou-container">
      <h1 className="thankyou-title">Thank You for Your Order!</h1>
      <p className="thankyou-message">
        We truly appreciate your trust in us. Your order has been successfully
        placed, and we are working on it to get it to you as soon as possible.
      </p>
      <p className="thankyou-message">
        If you have any questions, feel free to reach out to us. We’re here to
        help!
      </p>

      <div className="thankyou-contact-details">
        <p className="thankyou-contact-heading">
          <strong>Contact Us:</strong>
        </p>
        <p className="thankyou-contact-info">
          📞 Phone: <a href="tel:+639057334945">0905 733 4945</a>
          <br />
          📧 Email:{" "}
          <a href="mailto:sales@americabrandbazaar.com">
            sales@americabrandbazaar.com
          </a>
          <br />
          💬 Facebook:{" "}
          <a
            href="https://www.facebook.com/profile.php?id=61552938899633"
            target="_blank"
            rel="noopener noreferrer"
          >
            America Brand Bazaar
          </a>
        </p>
      </div>
    </div>
  );
};

export default ThankYou;
